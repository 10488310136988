import React, { useState, useRef, useEffect, FormEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Config
import Keys from "../../config/Keys";

// Components
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUser } from "@clerk/clerk-react";
import SearchFormQueryIcon from "../icons/SearchFormQuery";
import SearchFormLocationIcon from "../icons/SearchFormLocation";
import { useProductList } from "../../hooks/useProductList";
import { HomeProductCard } from "../HomeProductCard";
import { useCheckout } from "../../hooks/useCheckout";
import homeBanner1 from '@images/home-banner-1.jpg';
import homeBanner2 from '@images/home-banner-2.jpg';
import homeFormBg from '@images/home-form-bg.jpg';

const HomeAlt = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("returnFromStripe=true")) {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition");
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
        sessionStorage.removeItem("scrollPosition");
      }
    }
  }, [location]);

  return (
    <main>
      <Form />
      <div className="container py-20 xl:py-32">
        <div className="bg-primary-50 rounded-3xl flex flex-col md:flex-row overflow-hidden min-h-[400px]">
          <div className="w-full md:w-2/5">
            <img
              src={homeBanner1}
              alt=""
              className="object-cover w-full h-[224px] md:h-full"
            />
          </div>
          <div className="w-full md:w-3/5 p-6 md:p-16">
            <h2 className="font-semibold text-primary-600 mb-3 text-4xl">
            The Best Certified Organic Goods
            <br /> All in One Place
            </h2>
            <p className="mb-10 text-black text-lg">
            Market.Organic makes it easy to find or sell anything Certified Organic - 
            from seeds, to products, to produce, to contracts!
            </p>
            <Link
              to="/signup"
              className="bg-primary-600 hover:bg-primary-700 text-white rounded-full px-6 py-3 inline-block font-semibold"
            >
              Sign up for an account
            </Link>
          </div>
        </div>
      </div>
      <Details />
      <div className="container py-20">
        <div
          className="bg-primary-50 rounded-3xl flex overflow-hidden bg-cover bg-center"
          style={{ backgroundImage: `url(${homeBanner2})` }}
        >
          <div className="p-8 pb-64 md:p-12 lg:p-20 max-w-[600px]">
            <h2 className="font-semibold text-white mb-2 text-4xl">
              Create your own listings
            </h2>
            <p className="mb-10 text-white text-lg max-w-[390px] font-medium">
              Create detailed listings for
              specific Certified Organic goods you want to source or sell.
            </p>
            <Link
              to="/marketplace"
              className="bg-primary-600 hover:bg-primary-700 text-white rounded-full px-6 py-3 inline-block font-semibold"
            >
              Create your first listings
            </Link>
          </div>
        </div>
      </div>
      {/* Pricing section commented out as requested
      <Pricing />
      */}
    </main>
  );
};

type SearchFormInputs = {
  query?: string;
};

interface FormProps extends SearchFormInputs {
  onSearch?: (query: string) => void;
}

const Form = ({ query, onSearch }: FormProps) => {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchFormInputs>();

  const onSubmit: SubmitHandler<SearchFormInputs> = async ({ query }) => {
    if (onSearch) {
      onSearch(query);
    } else {
      navigate(`/search/${query}`, { state: { fromSearch: true } });
    }
  };

  return (
    <section
      className="py-28 bg-cover bg-center"
      style={{ backgroundImage: `url(${homeFormBg})` }}
    >
      <div className="container">
        <h1 className="text-4xl font-semibold text-center text-white">
          Source directly from Certified Organic
          <br /> farms, businesses, and cooperatives
        </h1>

        <div className="max-w-[480px] mx-auto mt-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col border border-primary-300 rounded-[20px] overflow-hidden shadow-sm bg-white">
              <div className="flex-1">
                <div>
                  <div className="relative">
                    <SearchFormQueryIcon />
                    <input
                      {...register("query")}
                      defaultValue={query}
                      required
                      type="text"
                      title="Search"
                      id="search"
                      placeholder="Search: tomatoes, rice, olive oil, etc."
                      className="rounded-[18px] block w-full h-[52px] px-3 text-lg pl-12 text-primary-500 placeholder:text-primary-400 font-medium focus:outline-none focus:ring-1 focus:ring-inset focus:ring-primary-300"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-3 rounded-full w-full shadow-sm font-semibold"
              >
                <FormattedMessage id="home.search.submit" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

const Details = () => {
  const intl = useIntl();

  return (
    <section className="pb-10">
      <div className="container">
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.account.title",
              })}
              description={intl.formatMessage({
                id: "home.how.account.description",
              })}
              type="account"
            />
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.verify.title",
              })}
              description={intl.formatMessage({
                id: "home.how.verify.description",
              })}
              type="verify"
            />
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.listing.title",
              })}
              description={intl.formatMessage({
                id: "home.how.listing.description",
              })}
              type="listing"
            />
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.contact.title",
              })}
              description={intl.formatMessage({
                id: "home.how.contact.description",
              })}
              type="contact"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

interface DetailsBlocProps {
  title: string;
  description: string;
  type: "account" | "verify" | "listing" | "contact";
}

const DetailsBloc = ({ title, description, type }: DetailsBlocProps) => {
  const iconComponent = () => {
    switch (type) {
      case "account":
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
          >
            <path
              d="M10.2421 35.6075C14.3053 33.3108 18.9996 32 24 32C29.0004 32 33.6947 33.3108 37.7579 35.6075M30 20C30 23.3137 27.3137 26 24 26C20.6863 26 18 23.3137 18 20C18 16.6863 20.6863 14 24 14C27.3137 14 30 16.6863 30 20ZM42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24Z"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "verify":
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
          >
            <path
              d="M18 24L22 28L30 20M41.2358 11.9687C40.8265 11.9894 40.4145 11.9999 40 11.9999C33.8531 11.9999 28.2459 9.68906 23.9999 5.88867C19.7538 9.68892 14.1468 11.9997 8 11.9997C7.58555 11.9997 7.17355 11.9892 6.76426 11.9684C6.26541 13.8957 6 15.9168 6 18C6 29.1831 13.6486 38.5797 24 41.2439C34.3514 38.5797 42 29.1831 42 18C42 15.9169 41.7346 13.8958 41.2358 11.9687Z"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "listing":
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
          >
            <path
              d="M18 10H14C11.7909 10 10 11.7909 10 14V38C10 40.2091 11.7909 42 14 42H34C36.2091 42 38 40.2091 38 38V14C38 11.7909 36.2091 10 34 10H30M18 10C18 12.2091 19.7909 14 22 14H26C28.2091 14 30 12.2091 30 10M18 10C18 7.79086 19.7909 6 22 6H26C28.2091 6 30 7.79086 30 10M24 24H30M24 32H30M18 24H18.02M18 32H18.02"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      case "contact":
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
          >
            <path
              d="M6 16L21.7812 26.5208C23.1248 27.4165 24.8752 27.4165 26.2188 26.5208L42 16M10 38H38C40.2091 38 42 36.2091 42 34V14C42 11.7909 40.2091 10 38 10H10C7.79086 10 6 11.7909 6 14V34C6 36.2091 7.79086 38 10 38Z"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="w-[48px] h-[48px] text-primary-400">
        {iconComponent()}
      </div>
      <div className="font-semibold text-primary-600 text-lg">{title}</div>
      <p>{description}</p>
    </div>
  );
};

/* Pricing component commented out as requested
const Pricing = () => {
  const { plans, loading: plansLoading } = useProductList();
  const {
    handleClick,
    loading: checkoutLoading,
    error,
  } = useCheckout("/signup/complete", "/signup/plans");

  const handlePlanClick = (plan) => {
    sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
    handleClick(plan);
  };

  return (
    <section className="py-20 mb-8 md:mb-16">
      <div className="container">
        <div className="text-center">
          <h2 className="text-4xl font-semibold text-primary-600 mb-10">
            Simple pricing, no commitment
          </h2>
          <p className="max-w-[700px] mx-auto text-xl font-medium text-primary-600">
            Certified Organic farms can use Market.Organic for free.
          </p>
        </div>
        <div className="mt-10 md:mt-16">
          <div className="grid grid-cols-1 gap-3 md:gap-10 lg:gap-0 lg:grid-cols-3">
            <HomeProductCard
              plan={plans.starter}
              handleClick={() =>
                handlePlanClick({ ...plans.starter, planName: "starter" })
              }
              loading={plansLoading || checkoutLoading}
              cardStyle="left"
            />
            <HomeProductCard
              plan={plans.premium}
              handleClick={() =>
                handlePlanClick({ ...plans.premium, planName: "premium" })
              }
              loading={plansLoading || checkoutLoading}
              cardStyle="middle"
            />
            <HomeProductCard
              plan={plans.organicFarms}
              handleClick={() =>
                handlePlanClick({ ...plans.organicFarms, planName: "organicFarms" })
              }
              loading={plansLoading || checkoutLoading}
              cardStyle="right"
            />
          </div>
        </div>
        {error && (
          <div className="mt-4 text-red-600 text-center">Error: {error}</div>
        )}
      </div>
    </section>
  );
};
*/

export { HomeAlt, Form };