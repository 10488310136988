import React, { useEffect } from "react";
import { Link } from "../Link";

const FAQItem = ({ question, answer }) => (
  <div className="mb-8">
    <h3 className="text-xl font-semibold text-primary-600 mb-3">{question}</h3>
    <div className="text-primary-900 space-y-2">{answer}</div>
  </div>
);

function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-primary-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <h1 className="font-bold text-4xl text-primary-600 mb-10">
            Frequently Asked Questions
          </h1>
          <div className="space-y-12">
            <FAQItem
              question="What is Market.Organic?"
              answer={
                <p>
                  Market.Organic is a platform that connects USDA Certified Organic farms and businesses with people who are looking for Certified Organic goods and Certified Organic contracts. 
                </p>
              }
            />

            <FAQItem
              question="How do I create an account on Market.Organic?"
              answer={
                <>
                  <p>To create an account on Market.Organic:</p>
                  <ol className="list-decimal pl-5 space-y-2">
                    <li>Visit www.market.organic.</li>
                    <li>Click on the "Sign Up" button in the top right corner.</li>
                  </ol>
                </>
              }
            />

            <FAQItem
              question="Is Market.Organic only for USDA Certified Organic products?"
              answer={
                <p>
                  Yes, Market.Organic is exclusively for USDA Certified Organic contracts and goods. To list a Certified Organic good on Market.Organic, you must be a USDA Certified Organic farm or business. 
                  This ensures everything for sale on Market.Organic is Certified Organic.
                </p>
              }
            />

            <FAQItem
              question="How does Market.Organic verify Certified Organic Operations?"
              answer={
                <p>
                  We verify Certified Organic Operations using the USDA Organic INTEGRITY Database. This public database contains information on all USDA-Certified Organic operations. We cross-reference the information provided by sellers with this database to ensure the authenticity of their Organic certification.
                </p>
              }
            />

            <FAQItem
              question="How do payments work on Market.Organic?"
              answer={
                <p>
                  Market.Organic facilitates connections between buyers and sellers, but does not process payments directly. Once a buyer and seller agree on a purchase, they can arrange payment methods between themselves. We recommend using secure, traceable payment methods for all transactions.
                </p>
              }
            />

            {/* <FAQItem
              question="Which fees does Market.Organic charge?"
              answer={
                <p>
                  Market.Organic offers free access to all users for up to 3 listings. 
                  Certified Organic farms can use the platform completely free of charge, regardless of the number of listings. 
                  For other businesses, both Certified Organic and non-Certified Organic, who wish to list more than 3 products, we offer a premium subscription that allows for unlimited listings. This subscription comes with a small fee. 
                  Buyers can always browse and connect with sellers for free, regardless of the number of products listed or the seller's subscription status.  
                </p>
              }
            /> */}

            <FAQItem
              question="Can I sell or buy non-food organic products using Market.Organic?"
              answer={
                <p>
                  Yes, Market.Organic supports the sale and purchase of all USDA Certified Organic products, including non-food items such as textiles, personal care products, and pet food. As long as the product is USDA Certified Organic, it can be listed on the platform.
                </p>
              }
            />

            <FAQItem
              question="How can I contact Market.Organic support?"
              answer={
                <p>
                  You can reach our support team by emailing <a href="mailto:support@market.organic" className="text-blue-400 hover:text-blue-600">support@market.organic</a>. We aim to respond to all inquiries within 24 hours during business days. 
                </p>
              }
            />
          </div>

          <div className="mt-12 text-center">
          </div>
        </div>
      </div>
    </main>
  );
}

export { FAQ };