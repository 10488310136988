import React from "react";
import { Link } from "react-router-dom";
import packageInfo from "../../../package.json";

const MainFooter = () => {
  return (
    <footer>
      <section className="border-t border-gray-200 py-20 pb-32">
        <div className="container">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 [&_a]:text-primary-800">
            <div className="flex flex-col gap-6">
              <div className="font-medium">Account</div>
              <ul className="text-sm flex flex-col gap-6">
                <li>
                  <Link to="/signup">Your Account</Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-6">
              <div className="font-medium">Service</div>
              <ul className="text-sm flex flex-col gap-6">
                <li>
                  <Link to="/FAQ">FAQ</Link>
                </li>
              </ul>
            </div>
            {/* <div className="flex flex-col gap-6">
              <div className="font-medium">Company</div>
              <ul className="text-sm flex flex-col gap-6">
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/PrivacyPolicy">Privacy</Link>
                </li>
              </ul>
            </div> */}
            <div className="flex flex-col gap-6">
              <div className="font-medium">Connect</div>
              <ul className="text-sm flex flex-col gap-6">
                <li>
                  <Link to="https://www.linkedin.com/company/market-organic/">LinkedIn</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 border-t border-gray-100">
        <div className="container">
          <div className="flex flex-col md:flex-row items-center md:justify-between gap-4">
            <div>
              <span className="text-sm md:border-r border-gray-200 px-3 py-1 text-primary-800">
                English
              </span>
            </div>
            <div className="text-sm text-primary-800">
              &copy; 2025 Market.Organic{" "}
              <span className="opacity-45"> - {packageInfo.version}</span>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export { MainFooter };
